<template>
  <div v-cloak class="fan-list px-12 py-8">
    <!-- Header Tools -->
    <div v-if="fans.length" class="d-flex justify-end align-center mb-4">
      <!-- Search -->
      <span>
        {{ search }}
        <v-text-field
          v-model="search"
          :label="$t('common.search')"
          prepend-inner-icon="search"
          tag="span"
          outlined
          dense
          hide-details
        />
      </span>
    </div>
    <v-data-table v-if="fans.length"
                  :headers="headers"
                  :items="fans"
                  :sort-by="['name']"
                  :sort-desc="false"
                  :search="search"
                  dense
    >
      <template #[`item.name`]="{ item }">
        <span class="text-capitalize" v-text="item.name" />
      </template>
      <template #[`item.birthdate`]="{ item }">
        <span class="text-capitalize" v-text="new Date(item.birthdate).toLocaleDateString()" />
      </template>
    </v-data-table>
    <empty-list v-else :content="$t('organization.sections.people')" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import i18nCountries from '@/utils/mixins/i18nCountries'

export default {
  name: 'FanList',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
  },
  mixins: [i18nCountries],
  props: {
    organizationId: {
      type: String,
      default: null,
    },
    projectId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fans: [],
      search: '',
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ isCompetition: 'project/isCompetition' }),
    ...mapGetters({ rawFans: 'projectUsers/rawFans' }),
    headers() {
      return [
        { text: this.$t('user.fields.name'), value: 'name' },
        { text: this.$t('user.fields.email'), value: 'email' },
        { text: this.$t('user.fields.country'), value: 'country' },
        { text: this.$t('user.fields.birthdate'), value: 'birthdate', filterable: false },
      ]
    },
  },
  watch: {
    rawFans(rawFans) { this.formatFans(rawFans) },
    countries() { this.formatFans(this.rawFans) },
  },
  async created() {
    const { organizationId, projectId } = this
    this.$store.commit('loader/show')
    if (!this.rawFans.length) await this.$store.dispatch('projectUsers/bindFans', { organizationId, projectId })
    await this.setCountriesLocale(this.$store.state.locale)
    this.formatFans(this.rawFans)
    if (this.$route.meta.scrollYPosition) window.scrollTo(0, this.$route.meta.scrollYPosition)
    this.$store.commit('loader/hide')
  },
  methods: {
    formatFans(rawFans) {
      this.fans = rawFans.map(fan => ({
        ...fan,
        name: `${fan.firstName} ${fan.lastName}`.trim(),
        birthdate: new Date(`${fan.birthdate.year}-${fan.birthdate.month}-${fan.birthdate.day}`).toISOString(),
        country: this.countries[fan.country],
      }))
    },
  },
}
</script>

<style lang="scss">

.fan-list {
  .search_field {
    max-width: 25rem;
  }

  .search_field > .v-input__control > .v-input__slot {
    margin-bottom: 0 !important;
  }
}

</style>
